<template>
  <v-app-bar class="app-header">
    <div
      class="app-header__logo"
      :class="{ mini: !isHomePage }"
      @click="openHomePage"
    >
      <v-fade-transition>
        <img src="@/assets/fairr.png" alt="Logo" key="logo" />
      </v-fade-transition>
    </div>
    <div class="app-header__title" v-if="isHomePage">
      FAIRR
    </div>
    <div class="app-header__title" v-if="pageTitle">
      {{ pageTitle }}
    </div>
    <div class="app-header__actions">
      <open-basket-button class="mr-1"/>
      <language-switcher />
      <auth-toggle-button />
    </div>
  </v-app-bar>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { computed } from "vue";

import OpenBasketButton from "@/components/basket/OpenBasketButton.vue";
import LanguageSwitcher from "@/components/helpers/LanguageSwitcher.vue";
import AuthToggleButton from "@/components/auth/AuthToggleButton.vue";
import { useI18n } from "vue-i18n";
import '@/assets/main.scss';

export default {
  name: "AppHeader",
  components: {
    OpenBasketButton,
    LanguageSwitcher,
    AuthToggleButton,
  },
  props: {
    title: String,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();

    const isHomePage = computed(() => route.path === "/");

    const pageTitle = computed(() => {
      if (isHomePage.value) return null;
      return t(route.meta?.title, route.meta?.title);
    });

    const openHomePage = () => {
      if (isHomePage.value) return;
      router.push("/");
    };

    return {
      isHomePage,
      pageTitle,
      openHomePage,
    };
  },
};
</script>

<style lang="scss">
.app-header {
  &.v-toolbar {
    padding: 0 16px;
  }

  &__logo {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    img {
      height: 36px;
      width: auto;
      display: block;
    }

    &.mini {
      width: 32px;
      overflow: hidden;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__title {
    font-size: 30px;
    font-weight: 600;
    margin-left: 16px;
    padding-left: 16px;
    border-left: 1px solid #ccc;
    font-family: 'American_Typewriter_Regular';
    line-height: 0.8;
  }
}
</style>
