<template>
  <v-btn
    id='login-button'
    v-if="!isUserLoggedIn"
    variant="text"
    color="primary"
    @click="showDialog"
  >
    Login
  </v-btn>

  <v-menu v-else>
    <template v-slot:activator="{ props }">
      <v-btn
        v-bind="props"
        variant="text"
        color="primary"
        icon="mdi-account"
      />
    </template>
    <v-list>
      <v-list-item to="/profile">
        <v-list-item-title>{{ t('common.profile') }}</v-list-item-title>
      </v-list-item>
      <v-list-item to="/orders">
        <v-list-item-title>{{ t('common.orders') }}</v-list-item-title>
      </v-list-item>
      <v-list-item @click="logout">
        <v-list-item-title>{{ $t('form.logout') }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>

  <auth-dialog v-model="isDialogOpen" />
</template>

<script>
import { computed, ref } from "vue";
import { useUserStore } from "@/stores/userStore";
import AuthDialog from "@/components/auth/AuthDialog.vue";
import {useRoute, useRouter} from "vue-router";
import {useI18n} from "vue-i18n";

export default {
  name: "AuthToggleButton",
  components: {
    AuthDialog
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();

    const isDialogOpen = ref(false);

    const userStore = useUserStore();

    const isUserLoggedIn = computed(() => userStore.isUserLoggedIn);

    const logout = () => {
      userStore.logout();
      if (route.path !== "/") {
        router.push("/");
      }
    };

    const showDialog = () => {
      isDialogOpen.value = true;
    };

    return {
      isUserLoggedIn,
      isDialogOpen,
      showDialog,
      logout,
      t,
    };
  },
};
</script>