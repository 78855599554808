import { createRouter, createWebHistory } from "vue-router";

import AppLayout from "@/components/layout/AppLayout.vue";
import analytics from "@/firebase";

const routes = [
  {
    path: "/",
    component: AppLayout,
    children: [
      {
        path: "",
        name: "home",
        component: () => import("@/views/HomeView.vue"),
      },
      {
        path: "basket",
        name: "basket",
        meta: {
          title: "common.cart",
        },
        component: () => import("@/views/BasketView.vue"),
      },
      {
        path: "orders",
        name: "orders",
        meta: {
          title: "common.orders",
        },
        component: () => import("@/views/OrdersView.vue"),
      },
      {
        path: "orders/:id",
        name: "order-details",
        meta: {
          title: "orders.order",
        },
        component: () => import("@/views/OrderView.vue"),
      },
      {
        path: "profile",
        name: "profile",
        meta: {
          title: "common.profile",
        },
        component: () => import("@/views/ProfileView.vue"),
      },
      {
        path: "shops/:id",
        name: "shop",
        props: true,
        meta: {
          title: "stores.store",
        },
        component: () => import("@/views/ShopView.vue"),
      },
      {
        path: "products/:id",
        name: "product",
        props: true,
        meta: {
          title: "products.productDetails",
        },
        component: () => import("@/views/ProductView.vue"),
      },
      {
        path: "/:catchAll(.*)", // Catch-all 404
        name: "not-found",
        meta: {
          title: "Not Found",
        },
        component: () => import("@/views/NotFoundView.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
