<template>
  <v-btn
    id='open-basket-button'
    v-if="isButtonVisible"
    variant="text"
    icon="mdi-shopping"
    color="primary"
    :disabled="isButtonDisabled"
    :to="{ name: 'basket' }"
  >
    <v-badge
      v-if="!isButtonDisabled"
      :content="selectedProductsCount"
      color="info"
    >
      <v-icon>mdi-shopping</v-icon>
    </v-badge>
  </v-btn>
</template>

<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";

import { useOrdersStore } from "@/stores/ordersStore";

const route = useRoute();

const ordersStore = useOrdersStore();

const selectedProductsCount = computed(() => ordersStore.basket.length);

const isButtonVisible = computed(() => route.name !== "basket" && selectedProductsCount.value > 0);

const isButtonDisabled = computed(() => selectedProductsCount.value === 0);
</script>
