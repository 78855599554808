import { initializeApp } from "firebase/app";
import * as FirebaseAnalytics from "firebase/analytics";

import {
  getAuth,
  signInAnonymously,
  onAuthStateChanged,
  EmailAuthProvider,
  linkWithCredential,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCglB2Dx2b8zriy4x4UuZqGV9AsDAwnDoM",
  authDomain: "fairr-fed94.firebaseapp.com",
  projectId: "fairr-fed94",
  storageBucket: "fairr-fed94.firebasestorage.app",
  messagingSenderId: "768708434247",
  appId: "1:768708434247:web:355d4c00fb7a5a99689462",
  measurementId: "G-0FG136G3RM"
};

const app = initializeApp(firebaseConfig);

const analytics = FirebaseAnalytics.getAnalytics(app);

const logEvent = (eventName, eventParams) => {
  FirebaseAnalytics.logEvent(analytics, eventName, eventParams);
  console.log("Event logged: ", eventName, eventParams);
};

export {
  signInAnonymously,
  onAuthStateChanged,
  EmailAuthProvider,
  linkWithCredential,
  analytics,
  logEvent
};
